import { render, staticRenderFns } from "./record_info_bak.vue?vue&type=template&id=05e2e9b5&scoped=true&"
import script from "./record_info_bak.vue?vue&type=script&lang=js&"
export * from "./record_info_bak.vue?vue&type=script&lang=js&"
import style0 from "./record_info_bak.vue?vue&type=style&index=0&id=05e2e9b5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e2e9b5",
  null
  
)

export default component.exports