<template>
  <div>
    <div class="container">
      <div class="list list3">
        <div class="listitem" >
          <img src="@/assets/sj.png" class="icon" />
          <input type="number" oninput="if(value.length > 4)value = value.slice(0, 11)" v-model="password" class="input" placeholder="请输入手机号码" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem">
          <div v-if="this.data.kgflag == false"><img src="@/assets/kgoff.png" @click="onKg" class="iconkg" /></div>
          <div v-if="this.data.kgflag == true"><img src="@/assets/kgon.png" @click="onKg" class="iconkg" /></div>
          <div class="text">所在药店</div>
          <div class="right">{{data.sitename}}</div>
        </div>
        <div class="listitem">
          <div v-if="flag == false"><img src="@/assets/c2.png"  @click="onKg" class="icon" /></div>
          <div v-if="flag == true"><img src="@/assets/c3.png"  @click="onKg" class="icon" /></div>
          <div class="text">所在柜台</div>
          <div class="right">{{data.clientname}}</div>
        </div>
      </div>
      <div class="list list2">
        <div class="listitem litem">
          <img :src="imgurl" class="iconkg1" />
        </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {  
  async created() {
    let { data } = await this.axios.post(
      '/ctid/enterpriseService/ceshiImg',
      qs.stringify({})
    );
    if(data.code=='1'){
      Toast(data.msg);
      //alert(data.imgsrc);
      this.imgurl = 'data:image/png;base64,' + data.imgsrc;  
    }else{
      Toast(data.msg);      
    }
  },
  data() {
    return {
      data: {
        // 1-管理员 2-负责人 3-游客
        kgflag: false
      },
      imgurl:'/img/jz.png',
      flag: false
    };
  },
  methods: {
    onKg() {
      
      if(this.data.kgflag){
        this.data.kgflag=false;
        this.flag=false;
        Dialog({ message: "<img src='/img/cg.png' width=100px height=60px/></br>简单家具的借款方康复科" });
      }else{
        this.data.kgflag=true;
        this.flag=true;
        Dialog({ message: "false" });
      }
    }
   
  }
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    &.litem {
      height: 500px;
    }
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .iconkg {
      width: 100px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .iconkg1 {
        margin-top: 34px;
        width: 320px;     
        float: right;
        margin-right: 34px;
      }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>